import { LogLevel } from '@wellro/utils';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyANykIkIsUPn4L_bo3zPfNS8f9o4Dq8Kj4',
    authDomain: 'wellro-wellness-preprod.firebaseapp.com',
    projectId: 'wellro-wellness-preprod',
    storageBucket: 'wellro-wellness-preprod.appspot.com',
    messagingSenderId: '313313290513',
    appId: '1:313313290513:web:d2d01dcf5f2c9339d384b0',
    measurementId: 'G-X3DVJGC7J2',
  },
  sentry: {
    dsn:
      'https://70510fe5dcd2445bb5ea176c5d805032@o4504682974740480.ingest.sentry.io/4504693742239744',
    env: 'pre-production',
    replaysSessionSampleRate: 0.5,
  },
  tenantId: 'doctor-qpw09',
  logLevel: LogLevel.WARNS,
  hostUrl: 'https://preprod-doctor.wellro.life',
};
